import { Link } from "react-router-dom";
import ProjectList from "../components/ProjectList";

const projects = require('../data/projects.json');


const Home = () => {
  return (
    <div className="layout">

      {/* Information */}

      <div>
        <div className="header">
          <Link to="/">L.R.G.</Link>
        </div>
        <hr />
        <div className="p">
          <div className="left-right-always">
            <div><img className="profile-img" src="/images/me_boardwalk_small.png" alt="" /></div>
            <div>Lucien is a software and deep learning engineer currently working at <a href="https://shield.ai/">Shield AI</a>.</div>
          </div>
          <p>
            → <Link to="/contact">Contact</Link>
          </p>
          <p>
            → <Link to="/music">Looking for my music?</Link>
          </p>
        </div>
      </div>

      {/* Projects */}

      <div>
        <div className="header">
          Selected projects
        </div>
        <hr />
        <ProjectList projects={ projects.programming } />
        <hr />
        <div className="p">
          There have been many, many projects in-between scattered all across the Internet. Please reach out if you discover and like one because that's always nice.
        </div>
      </div>
    </div>
  )  
}

export default Home;